import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, withFormik } from "formik";
import { Button, Typography, Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import {
  FormBodyContainer,
  FormField,
  FormHeaderContainer,
  PreviousButton
} from "../../components";
import ChildQuestionField from "./ChildQuestionField";
import YearOptions from "./YearOptions";
import { validateRequiredFiled, hasFieldError } from "../../../utils/validator";
import { createGTMEvent } from "../../../utils/googleTagManager";
import { questionActions } from "../../../state/question";
import { leadActions } from "../../../state/lead";
import { trackActions } from "../../../state/track";
import {
  birthAndGradYearDiff,
  pixelEventName,
  snapchatPixelEventName
} from "../../../config";

class StepTwoForm extends Component {
  state = {
    isFormComplete: false,
    isDefaultValuesSent: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { values, setFieldValue, trackPixel, leadData, trackSnapchatPixel } =
      this.props;
    createGTMEvent({
      event: "Step Conversion",
      label: "Step 2",
      action: "Load"
    });

    trackPixel({
      step: "2",
      eventName: pixelEventName.pageView,
      leadData
    });

    trackPixel({
      step: "2",
      eventName: pixelEventName.completeRegistration,
      leadData
    });
    trackSnapchatPixel({
      step: "2",
      eventName: snapchatPixelEventName.pageView,
      eventTag: "Lead Conversion",
      leadData
    });
    trackSnapchatPixel({
      step: "2",
      eventName: snapchatPixelEventName.signup,
      eventTag: "Lead Conversion",
      leadData
    });

    if (values.birthYear > values.gradYear - birthAndGradYearDiff) {
      setFieldValue("birthYear", "");
    }
  }

  componentDidUpdate() {
    const { isDefaultValuesSent } = this.state;
    const { currentStep, leadData, updateLead } = this.props;
    if (!isDefaultValuesSent && leadData.id) {
      const computerAccessData = {
        questionName: "hasComputerAccess",
        questionValue: "Y",
        step: currentStep
      };
      const genderData = {
        questionName: "gender",
        questionValue: "F",
        step: currentStep
      };
      const citizenshipData = {
        questionName: "isUSCitizen",
        questionValue: "Y",
        step: currentStep
      };
      // const selectedProgramData = {
      //   questionName: "selectedProgramIds",
      //   questionValue: values.programId,
      //   step: currentStep
      // };

      updateLead(leadData.id, computerAccessData);
      updateLead(leadData.id, genderData);
      updateLead(leadData.id, citizenshipData);
      // updateLead(leadData.id, selectedProgramData);
      this.setState({ isDefaultValuesSent: true });
    }
  }

  onPreviousStepButtonClicked = data => {
    const { goToPreviousStep, values, errors, touched } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 2 Previous Button",
      action: "Click"
    });

    goToPreviousStep(values, !hasFieldError(errors, touched));
  };

  onNextStepButtonClicked = data => {
    const { goToNextStep, values } = this.props;

    createGTMEvent({
      event: "Step Conversion",
      label: "Step 2 Next Button",
      action: "Click"
    });

    goToNextStep(values);
  };

  handleOnBlur = ({ target: { name } }, questionName) => {
    const {
      setFieldTouched,
      values,
      updateLead,
      leadData,
      currentStep,
      setCompletedSteps,
      errors,
      touched,
      stepCompleted
    } = this.props;
    let data = {
      questionName,
      step: currentStep,
      questionValue: values[name]
    };

    setFieldTouched(name, true);
    if (leadData.id && values[name] && !Object.keys(errors).includes(name)) {
      updateLead(leadData.id, data);
    }

    if (!values[name]) {
      let steps = [...stepCompleted];
      let idx = stepCompleted.findIndex(s => s === currentStep);
      if (idx > -1) {
        steps.splice(idx, 1);
      }
      setCompletedSteps(steps);
    } else {
      let steps = [...stepCompleted];
      if (!hasFieldError(errors, touched) && !steps.includes(currentStep)) {
        setCompletedSteps([...steps, currentStep]);
      }
    }
  };

  render() {
    const {
      questions,
      allQuestions,
      locationData,
      errors,
      touched,
      values,
      currentStep,
      stepCompleted
    } = this.props;

    return (
      <Fragment>
        <FormHeaderContainer className="form-header">
          <Typography variant="h5">Personal Information</Typography>
        </FormHeaderContainer>
        <FormBodyContainer className="form-body">
          <Form>
            {questions.length &&
              questions.map((question, idx) => {
                switch (question.type) {
                  case "dropdown":
                    return !question.isDependent ? (
                      <Fragment key={idx}>
                        <FormField
                          name={`${question.metaKey}`}
                          label={question.label}
                          component="select"
                          validate={validateRequiredFiled}
                          touched={touched[`${question.metaKey}`]}
                          error={errors[`${question.metaKey}`]}
                          onBlur={e => {
                            this.handleOnBlur(e, question.metaKey);
                          }}
                          hidden={question.metaKey === "hasComputerAccess"}
                        >
                          <option value="">- Select -</option>
                          {question.metaKey === "birthYear" && (
                            <YearOptions
                              startYear={values.gradYear - birthAndGradYearDiff}
                            ></YearOptions>
                          )}
                          {question.metaKey !== "birthYear" &&
                            question.options &&
                            question.options.length &&
                            question.options.map((option, index) => (
                              <option key={index} value={option.value}>
                                {option.text || option.name}
                              </option>
                            ))}
                        </FormField>

                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                />
                              );
                            }

                            return "";
                          })}
                      </Fragment>
                    ) : (
                      ""
                    );
                  default:
                    return (
                      <Fragment key={idx}>
                        <FormField
                          type="text"
                          name={`${question.metaKey}`}
                          label={question.label}
                          placeholder={question.label}
                          validate={validateRequiredFiled}
                          touched={touched[`${question.metaKey}`]}
                          error={errors[`${question.metaKey}`]}
                          onBlur={e => {
                            this.handleOnBlur(e, question.metaKey);
                          }}
                        />
                        {question.hasDependent &&
                          question.dependents.length &&
                          question.dependents.map((dependentQuestion, idx) => {
                            let childQuestion = allQuestions.filter(
                              q =>
                                dependentQuestion.questionKey === q.metaKey &&
                                dependentQuestion.condition.includes(
                                  values[`${question.metaKey}`]
                                )
                            );
                            if (childQuestion.length) {
                              return (
                                <ChildQuestionField
                                  childQuestion={childQuestion[0]}
                                  touched={touched}
                                  errors={errors}
                                  handleOnBlur={this.handleOnBlur}
                                  key={idx}
                                />
                              );
                            }
                            return "";
                          })}
                        {question.metaKey === "streetAddress" &&
                          locationData && (
                            <Box mt={2}>
                              <Typography>City, State, Zipcode</Typography>
                              <Typography color="primary">
                                {`${locationData.city}, ${locationData.state}, ${locationData.zipCode}`}
                              </Typography>
                            </Box>
                          )}
                      </Fragment>
                    );
                }
              })}

            <Box mt={3} align="right">
              <PreviousButton
                onClick={this.onPreviousStepButtonClicked}
                id="step2-previous-button"
              >
                Previous Step
              </PreviousButton>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onNextStepButtonClicked}
                disabled={
                  !stepCompleted.includes(currentStep) &&
                  hasFieldError(errors, touched)
                }
                id="step2-next-button"
              >
                Next
                <KeyboardArrowRight />
              </Button>
            </Box>
          </Form>
        </FormBodyContainer>
      </Fragment>
    );
  }
}

const StepTwoQuestions = withFormik({
  mapPropsToValues: ({ formData }) => ({
    gender: "F",
    isUSCitizen: "Y",
    hasComputerAccess: "Y",
    ...formData
  }),
  handleSubmit(values, { props, setFieldError, setSubmitting }) {
    let data = { ...values };
    console.log(data);
  }
})(StepTwoForm);

const mapStateToProps = ({ question, lead, location }) => {
  return {
    formData: question.formData,
    currentStep: question.currentStep,
    allQuestions: question.allQuestions,
    stepCompleted: question.stepCompleted,
    leadData: lead.leadData,
    locationData: location.locationData
  };
};

const mapActionsToProps = {
  goToPreviousStep: questionActions.goToPreviousStep,
  goToNextStep: questionActions.goToNextStep,
  setCompletedSteps: questionActions.setCompletedSteps,
  updateLead: leadActions.updateLead,
  trackPixel: trackActions.trackPixel,
  trackSnapchatPixel: trackActions.trackSnapchatPixel
};

export default connect(mapStateToProps, mapActionsToProps)(StepTwoQuestions);
