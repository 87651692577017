import React from "react";
import styled from "styled-components";
import { color } from "styled-system";
import Image from "react-shimmer";

const ImageWrapper = styled.div`
  padding: 4px;
  // background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  ${color}
`;

const ImageViewer = ({ bg = "white", imageUrl, altName }) => {
  return (
    <ImageWrapper bg={bg}>
      <Image
        src={imageUrl}
        width={250}
        height="auto"
        alt={altName}
        style={{ objectFit: "cover" }}
      />
    </ImageWrapper>
  );
};

export default ImageViewer;
