import { getDomainFromURL, getURLParameter } from "./URLUtils";

export const isDataLayerLoaded = () => {
  return new Promise((resolve, reject) => {
    const timeStart = Date.now();
    const TIMEOUT = 30000;

    const _isLoaded = function () {
      if (Date.now() - timeStart > TIMEOUT) {
        reject("GTM load failed");
        return;
      }
      if (window && window.dataLayer) {
        resolve(window.dataLayer);
        return;
      } else {
        setTimeout(_isLoaded, 500);
      }
    };

    _isLoaded();
  });
};

export const initializeGTM = () => {
  if (window && window.dataLayer) {
    window.referringDomain =
      getURLParameter("referringDomain") || getDomainFromURL(document.referrer);
    window.dataLayer.push({
      pageInfo: {
        widgetId: getURLParameter("widget"),
        referringDomain: window.referringDomain,
        campaignId: getURLParameter("campaignId")
      }
    });
  }
};

export const createGTMEvent = (data, condition) => {
  if (window && window.dataLayer) {
    if (!condition) {
      window.dataLayer.push({
        category: window.referringDomain,
        ...data
      });
    } else {
      const index = window.dataLayer.findIndex(
        data => data.event === condition
      );
      if (index !== -1) {
        window.dataLayer.push({
          category: window.referringDomain,
          ...data
        });
      }
    }
  }
};
