import React from "react";
import { categories } from "../../../config";

const ProgramOptions = ({ feedOptions }) => {
  let options = [];
  Object.keys(feedOptions).length &&
    Object.keys(feedOptions).forEach(categoryId => {
      if (feedOptions[categoryId].length) {
        let programs = [];
        feedOptions[categoryId].forEach(({ text, value }) =>
          programs.push(
            <option key={value} value={value}>
              {text}
            </option>
          )
        );
        options.push(
          <optgroup key={categoryId} label={categories[categoryId]}>
            {programs}
            <option key="1000" value="1000">
              I'm Not Sure
            </option>
          </optgroup>
        );
      }
    });
  return options;
};

export default ProgramOptions;
