import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, IconButton, Box, Button } from "@material-ui/core";
import { ContactSupport } from "@material-ui/icons";
import { Dialog } from ".";
import styled from "styled-components";
import { matchActions } from "../../state/match";

const Notice = styled.div`
  display: inline;
`;

export default function TCPATextModal({ schoolName, tcpaConcentText }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedSchoolOptions = useSelector(
    state => state.match.selectedSchoolOptions
  );
  const handleSelectOption = option => {
    dispatch(
      matchActions.setSelectedSchoolOptions([...selectedSchoolOptions, option])
    );
    setIsDialogOpen(false);
  };
  const handleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const parseTcpaConcentText = node => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName === "A") {
      return (
        <a
          key={node.href}
          href={node.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.textContent}
        </a>
      );
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const children = [];
      node.childNodes.forEach((childNode, index) => {
        children.push(
          <React.Fragment key={index}>
            {parseTcpaConcentText(childNode)}
          </React.Fragment>
        );
      });
      return children;
    }
    return null;
  };

  return (
    <>
      <Notice>
        <IconButton size="small" onClick={handleDialog}>
          <ContactSupport style={{ color: "#007cba" }} fontSize="small" />
        </IconButton>
      </Notice>
      <Dialog
        open={isDialogOpen}
        handleClose={handleDialog}
        heading={schoolName}
      >
        <Box>
          <Typography gutterBottom={true}>
            {parseTcpaConcentText(
              new DOMParser().parseFromString(tcpaConcentText, "text/html").body
            )}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={3} pb={2}>
          <Button
            onClick={() => handleSelectOption(schoolName)}
            color="primary"
            variant="contained"
          >
            Select
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
