import * as types from "./types";

const initialState = {
  isFetchingAllQuestions: false,
  fetchAllQuestionsDone: false,
  fetchAllQuestionsFailed: false,
  isSearchingFeedOptions: false,
  searchFeedOptionsDone: false,
  searchFeedOptionsFailed: false,
  allQuestions: [],
  feedOptions: {},
  formData: {},
  currentStep: 1,
  stepCompleted: [],
  stageTwoSearch: false
};

const questionReducers = function (state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_ALL_QUESTIONS:
      return {
        ...state,
        isFetchingAllQuestions: true,
        fetchAllQuestionsDone: false,
        fetchAllQuestionsFailed: false
      };
    case types.FETCH_ALL_QUESTIONS_DONE:
      return {
        ...state,
        isFetchingAllQuestions: false,
        fetchAllQuestionsDone: true,
        fetchAllQuestionsFailed: false,
        allQuestions: payload.data
      };
    case types.FETCH_ALL_QUESTIONS_FAILED:
      return {
        ...state,
        isFetchingAllQuestions: false,
        fetchAllQuestionsDone: false,
        fetchAllQuestionsFailed: true
      };
    case types.FETCH_ALL_QUESTIONS_RESUME:
      return {
        ...state,
        isFetchingAllQuestions: true,
        fetchAllQuestionsDone: false,
        fetchAllQuestionsFailed: false
      };
    case types.FETCH_ALL_QUESTIONS_RESUME_DONE:
      return {
        ...state,
        isFetchingAllQuestions: false,
        fetchAllQuestionsDone: true,
        fetchAllQuestionsFailed: false,
        allQuestions: payload.data,
        currentStep: 3
      };
    case types.FETCH_ALL_QUESTIONS_RESUME_FAILED:
      return {
        ...state,
        isFetchingAllQuestions: false,
        fetchAllQuestionsDone: false,
        fetchAllQuestionsFailed: true
      };
    case types.SEARCH_FEED_OPTIONS:
      return {
        ...state,
        isSearchingFeedOptions: true,
        searchFeedOptionsDone: false,
        searchFeedOptionsFailed: false
      };
    case types.SEARCH_FEED_OPTIONS_DONE:
      return {
        ...state,
        isSearchingFeedOptions: false,
        searchFeedOptionsDone: true,
        searchFeedOptionsFailed: false,
        feedOptions: payload.data
      };
    case types.SEARCH_FEED_OPTIONS_FAILED:
      return {
        ...state,
        isSearchingFeedOptions: false,
        searchFeedOptionsDone: false,
        searchFeedOptionsFailed: true
      };
    case types.GO_TO_NEXT_STEP:
      return {
        ...state,
        currentStep:
          state.currentStep === 4 ? state.currentStep : state.currentStep + 1,
        stageTwoSearch: false,
        formData: { ...state.formData, ...payload }
      };
    case types.GO_TO_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
        formData: { ...state.formData, ...payload }
      };
    case types.GO_TO_STAGE_TWO_SEARCH:
      return {
        ...state,
        stageTwoSearch: true,
        currentStep: state.currentStep + 1,
        formData: { ...state.formData, ...payload }
      };
    case types.SET_COMPLETED_STEPS:
      return {
        ...state,
        stepCompleted: [...payload]
      };
    default:
      return state;
  }
};

export default questionReducers;
