import * as types from "./types";

export const fetchAllQuestions = () => ({
  type: types.FETCH_ALL_QUESTIONS,
  meta: {
    async: true,
    blocking: true,
    path: `/questions?status=Active`,
    method: "GET",
    body: {}
  }
});

export const fetchAllQuestionsResume = () => ({
  type: types.FETCH_ALL_QUESTIONS_RESUME,
  meta: {
    async: true,
    blocking: true,
    path: `/questions?status=Active`,
    method: "GET",
    body: {}
  }
});
export const searchFeedOptions = (feedName, categoryId) => ({
  type: types.SEARCH_FEED_OPTIONS,
  meta: {
    async: true,
    blocking: true,
    path: `/questions/search-option/${feedName}`,
    method: "POST",
    body: {
      categoryId
    }
  }
});

export const goToNextStep = data => ({
  type: types.GO_TO_NEXT_STEP,
  payload: data
});

export const goToStageTwoSearch = data => ({
  type: types.GO_TO_STAGE_TWO_SEARCH,
  payload: data
});

export const goToPreviousStep = data => ({
  type: types.GO_TO_PREVIOUS_STEP,
  payload: data
});

export const setCompletedSteps = steps => ({
  type: types.SET_COMPLETED_STEPS,
  payload: steps
});
