import React, { useState } from "react";
import { Typography, IconButton, Box, Button } from "@material-ui/core";
import { ContactSupport } from "@material-ui/icons";
import { Dialog } from "../components";
import styled from "styled-components";
import { siteNames } from "../../config";

const Notice = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => center || "flex-start"};
`;

const getSiteName = (url = "") => {
  for (let site in siteNames) {
    if (url.includes(site)) {
      return siteNames[site];
    }
  }

  return "My College Laptop";
};

export default function ComplianceNotice({ center, referringDomain }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <>
      <Notice center={center}>
        <Typography style={{ color: "#666666" }} variant="caption">
          SPONSORED LISTINGS
        </Typography>
        <IconButton size="small" onClick={handleDialog}>
          <ContactSupport style={{ color: "#007cba" }} fontSize="small" />
        </IconButton>
      </Notice>
      <Dialog
        open={isDialogOpen}
        handleClose={handleDialog}
        heading="Sponsored meaning explained.
"
      >
        <Box>
          <Typography gutterBottom={true}>
            {getSiteName(referringDomain)} receives compensation for the
            featured schools on our websites (see "Sponsored Schools" or
            "Sponsored Listings" or "Sponsored Results" or "Featured Schools")
            So what does this mean for you?
          </Typography>
          <Typography gutterBottom={true}>
            Compensation may impact where the Sponsored Schools appear on our
            websites, including whether they appear as a match through our
            education matching services tool, the order in which they appear in
            a listing, and/or their ranking. Our websites do not provide, nor
            are they intended to provide, a comprehensive list of all schools
            (a) in the United States (b) located in a specific geographic area
            or (c) that offer a particular program of study. By providing
            information or agreeing to be contacted by a Sponsored School, you
            are in no way obligated to apply to or enroll with the school.
          </Typography>
          <Typography gutterBottom={true}>
            Your trust is our priority. We at {getSiteName(referringDomain)}{" "}
            believe you should make decisions about your education with
            confidence. That's why {getSiteName(referringDomain)} is also proud
            to offer free information on its websites, which has been used by
            prospective students to explore their education goals and interests.
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={3} pb={2}>
          <Button onClick={handleDialog} color="primary" variant="contained">
            Close
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
