import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Image from "react-shimmer";

const CardWrapper = styled.div`
  .logo-container {
    width: 80%;
    height: auto;
    padding: 8px;
    border: 1px solid #c9c9c9;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const LogoDisplayFormat = ({ logoUrl, name }) => {
  const [shimmerWidth, setShimmerWidth] = useState(130);
  const [shimmerHeight, setShimmerHeight] = useState(70);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 570) {
        setShimmerWidth(80);
        setShimmerHeight(35);
      } else {
        setShimmerWidth(130);
        setShimmerHeight(50);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <CardWrapper>
      <div className="logo-container">
        <Image
          src={logoUrl}
          alt={name}
          height={shimmerHeight}
          width={shimmerWidth}
          style={{ objectFit: "cover" }}
        />
      </div>
    </CardWrapper>
  );
};

export default LogoDisplayFormat;
