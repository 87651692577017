import { pixelEventName, fbPixelsIds } from "../config";
import { getCookie } from "./cookie";
import SHA256 from "crypto-js/sha256";

function getPixelId(referralURL) {
  let pixelIds = [];

  const isMyCollegeLaptop = referralURL.includes("mycollegelaptop.com");
  const isAspiringNurse = referralURL.includes("aspiringnurse.com");
  const isMyLaptopIsACollege = referralURL.includes("mylaptopisacollege.com");
  const isDegreeSolutions = referralURL.includes("degreesolutions.com");
  const isSupportiveColleges = referralURL.includes("supportivecolleges.com");

  if (isMyCollegeLaptop) {
    pixelIds = [
      fbPixelsIds.mycollegelaptop
      // fbPixelsIds.mycollegelaptopV2,
      // fbPixelsIds.mycollegelaptopV3
    ];
  }

  if (isAspiringNurse) {
    pixelIds = [fbPixelsIds.aspiringnurse];
  }

  if (isMyLaptopIsACollege) {
    pixelIds = [fbPixelsIds.mylaptopisacollege];
  }

  if (isDegreeSolutions) {
    pixelIds = [fbPixelsIds.degreesolutions];
  }
  if (isSupportiveColleges) {
    pixelIds = [fbPixelsIds.supportivecolleges, fbPixelsIds.mycollegelaptop];
  }

  return pixelIds;
}

export function fbpInit(referralURL) {
  let initialized = typeof window !== "undefined" && !!window.fbq;
  const pixelIds = getPixelId(referralURL);
  const userCookie = getCookie();

  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  /* eslint-enable */

  if (!initialized && pixelIds.length) {
    for (let i = 0; i < pixelIds.length; i++) {
      fbq("init", pixelIds[i], { external_id: userCookie }); // eslint-disable-line no-undef
    }
  }
}

export const isFbpLoaded = () => {
  return new Promise((resolve, reject) => {
    const timeStart = Date.now();
    const TIMEOUT = 30000;

    const _isLoaded = function () {
      if (Date.now() - timeStart > TIMEOUT) {
        reject("FBP load failed");
        return;
      }
      if (window && window.fbq) {
        resolve(window.fbq);
        return;
      } else {
        setTimeout(_isLoaded, 500);
      }
    };

    _isLoaded();
  });
};

export const createFbpEvent = ({ eventName, eventId, step, leadData }) => {
  const {
    zip,
    email,
    employment,
    bestTimeToContact,
    levelOfEducation,
    userCookie,
    gender
  } = leadData;

  const customData = {
    content_name: `step ${step}`,
    country: SHA256("us").toString(),
    ...(employment && { employment: employment }),
    ...(bestTimeToContact && { best_time_to_contact: bestTimeToContact }),
    ...(levelOfEducation && { level_of_education: levelOfEducation }),
    ...(email && { em: SHA256(email).toString() }),
    ...(zip && { zp: SHA256(zip).toString() }),
    ...(gender && { ge: SHA256(gender.toLowerCase()).toString() }),
    ...(userCookie && { external_id: userCookie })
  };

  if (
    eventName === pixelEventName.completeRegistration ||
    eventName === pixelEventName.lead
  ) {
    customData["value"] = 1.0;
    customData["currency"] = "USD";
  }

  if (window && window.fbq) {
    window.fbq("track", eventName, { ...customData }, { eventID: eventId });
  }
};
